import React, {useEffect, useRef, useState} from 'react';
import "./assets/App.css";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import Lottie from "lottie-react";
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import logoLigoBet from "./assets/logo/ligobet.png";
import logoGarBet from "./assets/logo/betgar.gif";
import logoRoketBet from "./assets/logo/roket.svg";
import logoRomaBet from "./assets/logo/romabet.gif";

const data = [

  {
    title: "250₺ DENEME BONUSU ",
    sub_title: "%30 FREEBET YATIRIM BONUSU l ERTESİ GÜN PRAGMATİC BONUSU",
    logo: logoRomaBet,
    gradient: ["rgb(0,0,0)", "rgb(200,0,255)"],
    link: "https://cutt.ly/HeXqhCjm",
    silver: false
  },

  {
    title: "250 TL DENEME BONUSU",
    sub_title: "CEVRIMSIZ %100 FREESPIN | 5X YAP 7X ÇEK CHALLANGE",
    logo: logoGarBet,
    gradient: ["rgb(0,0,0)", "rgb(17,0,255)"],
    link: "https://cutt.ly/betgargir",
    silver: false
  },
  {
    title: "250₺ DENEME BONUSU",
    sub_title: "%175'e VARAN YATIRIM BONUSU l ROKET ÇARK",
    logo: logoRoketBet,
    gradient: ["rgb(0,0,0)", "rgb(0,255,178)"],
    link: "https://cutt.ly/PeXqj5zb",
    silver: true
  },
  {
    title: "500 TL DENEME BONUSU",
    sub_title: "%200 BONUS BUY | %1000 SADAKAT BONUSU",
    logo: logoLigoBet,
    gradient: ["rgb(0,0,0)", "rgb(255,196,0)"],
    link: "https://cutt.ly/ligogir",
    silver: false
  }
];



const ButtonCard = (item) => {

  const [getHoverClass, setHoverClass] = useState(item.index === 0);
  const [dotLottie, setDotLottie] = React.useState(null);


  useEffect(() => {
    if(dotLottie !== null) {
      item.index === 0 && setTimeout(() => {
        dotLottie?.play()
      },100)
    }
  },[dotLottie])

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  return (
    <div
      className={`${getHoverClass && "block"}`}
      onMouseEnter={() => {
        setHoverClass(true);
        dotLottie?.play();
      }}
      onMouseLeave={() => {
        setHoverClass(false);
        dotLottie?.pause();
      }}
      style={{
        position:"relative",
        border: "0.2px solid rgb(255 255 255 / 52%)",
        marginBottom: 15,
        width: "48%",
        height: 190,
        borderRadius: 30,
        backgroundSize:"100% 100%",
        backgroundImage: getHoverClass ? `url(${require("./assets/gradient/day-1_2x.jpg")})` : `linear-gradient(89deg, ${item.gradient[0]} 31%, ${item.gradient[1]} 122%)`
      }}>
      <img
        style={{marginTop: 30, marginLeft: 20, marginBottom: 0}}
        src={item.logo}
        height={30} alt=""/>
      <p style={{
        marginTop: 10,
        marginLeft: 20,
        fontWeight: 900,
        fontSize: 18,
        color: "white",
        marginBottom: 0
      }}>
        {item.title}
      </p>
      <p style={{
        marginTop: 10,
        marginLeft: 20,
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 9,
        color: "rgb(255 255 255 / 44%)"
      }}>
        {item.sub_title}
      </p>
      <a
        onClick={() => {
          window.open(item.link,'_blank');
        }}   style={{display: "block", width: 110}}>
        <div style={{
          paddingLeft: "10px",
          paddingRight: "10px",
          background: item.gradient[1],
          width: "90px",
          height: "34px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          borderRadius: "100px",
          color: "black",
          marginLeft: "20px",
          fontSize: "13px",
          alignContent: "center",
          marginTop: 15
        }}>
          Siteye Git <ArrowRightOutlined/>
        </div>
      </a>

      {
        item.silver &&
          <div
            style={{position: "absolute", right: 10, top: 40}}>
            <DotLottieReact
              dotLottieRefCallback={dotLottieRefCallback}
              src={require('./assets/sil.lottie')}
              loop
              style={{height: 75}}
            />

          </div>
      }

    </div>
  )
}

function Desktop() {
  return (
    <div style={{
      width: '100%',
      height: "100%",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: "relative",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>


      <div style={{
        backgroundImage: `url(${require("./assets/gradient/day-1_2x.jpg")})`,
        backgroundSize: "100% 100%",
        position: "absolute",
        zIndex: 1,
        width: "100%",
        height: "100%",
        left: 0,
        top: 0
      }}/>

      <div style={{
        opacity: 0.2,
        backgroundImage: `url(${require("./assets/bg.png")})`,
        position: "absolute",
        zIndex: 1,
        width: "100%",
        height: "100%",
        left: 0,
        top: 0
      }}/>


      <div style={{
        overflow: "hidden",
        position: "absolute",
        zIndex: 2,
        left: 0,
        top: 0
      }}>
        <DotLottieReact
          src={require('./assets/casino.lottie')}
          loop
          autoplay
          style={{height: 400, position: "relative", left: -400, top: -160}}
        />
      </div>
      <div style={{
        overflow: "hidden",
        position: "absolute",
        zIndex: 2,
        left: 0,
        bottom: 0
      }}>
        <DotLottieReact
          src={require('./assets/coin.lottie')}
          loop
          autoplay
          style={{height: 100, position: "relative", left: -50}}
        />
      </div>

      <div style={{
        overflow: "hidden",
        position: "absolute",
        zIndex: 2,
        right: 0,
        bottom: 0
      }}>
        <Lottie autoplay animationData={require('./assets/football-2.json')}
                style={{height: 400, position: "relative", right: -190, bottom: -50}} loop={false}/>
      </div>


      <div style={{width: 1200, height: "100%", position: "absolute", zIndex: 2}}>
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9
        }}>
          <p style={{
            fontFamily: "Roboto",
            fontSize: "75px",
            fontWeight: 900,
            marginBottom: 0,
            color: "white",
            WebkitTextFillColor: "transparent",
            background: "linear-gradient(90.11deg, rgb(236, 56, 77) 0.09%, rgb(238, 154, 27) 27.13%, rgb(146, 130, 239) 61.97%, rgb(255 52 0) 99.92%) text, text"
          }}> ŞİMDİ KAZANMA VAKTİ</p>
          <p style={{
            fontFamily: "Roboto",
            fontSize: "22px",
            fontWeight: 300,
            color: "white",
            marginTop: 30,
            marginBottom: 100,
            textAlign: "center",
            lineHeight: 2
          }}>
            SANA ÖZEL FIRSATLARLA HEMEN KAYIT OL, İLK YATIRIMINA VE GİRŞİNE ÖZEL BONUS VE DAHA FAZLASI BURADA <br/>
            AŞAĞIDA YER ALAN BAĞLANTILARDAN ERİŞ VE KAZANMAYA BAŞLA
          </p>
        </div>

        <div style={{display: "flex", gap: "1%", flexWrap: "wrap"}}>
          {

            data.map((item, index) => <ButtonCard {...item} index={index}/>)
          }
        </div>

      </div>
    </div>
  );
}

export default Desktop;
