import React from 'react';
import ReactDOM from 'react-dom/client';
import Desktop from './Desktop';
import "./assets/App.css"
import {isDesktop, isMobile, isSmartTV, isTablet} from "react-device-detect";
import Mobile from "./Mobile";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    { (isDesktop || isTablet || isSmartTV) && <Desktop /> }
    { isMobile && <Mobile /> }
  </React.StrictMode>
);
