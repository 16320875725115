import React, {useEffect, useRef, useState} from 'react';
import "./assets/App.css";
import {DotLottieReact} from "@lottiefiles/dotlottie-react";
import Lottie from "lottie-react";
import {
  ArrowRightOutlined
} from '@ant-design/icons';
import logoLigoBet from "./assets/logo/ligobet.png";
import logoGarBet from "./assets/logo/betgar.gif";
import logoRoketBet from "./assets/logo/roket.svg";
import logoRomaBet from "./assets/logo/romabet.gif";

const data = [

  {
    title: "250₺ DENEME BONUSU ",
    sub_title: "%30 FREEBET YATIRIM BONUSU l ERTESİ GÜN PRAGMATİC BONUSU",
    logo: logoRomaBet,
    gradient: ["rgb(0,0,0)", "rgb(200,0,255)"],
    link: "https://cutt.ly/HeXqhCjm",
    silver: false
  },

  {
    title: "250 TL DENEME BONUSU",
    sub_title: "CEVRIMSIZ %100 FREESPIN | 5X YAP 7X ÇEK CHALLANGE",
    logo: logoGarBet,
    gradient: ["rgb(0,0,0)", "rgb(17,0,255)"],
    link: "https://cutt.ly/betgargir",
    silver: false
  },
  {
    title: "250₺ DENEME BONUSU",
    sub_title: "%175'e VARAN YATIRIM BONUSU l ROKET ÇARK",
    logo: logoRoketBet,
    gradient: ["rgb(0,0,0)", "rgb(0,255,178)"],
    link: "https://cutt.ly/PeXqj5zb",
    silver: true
  },
  {
    title: "500 TL DENEME BONUSU",
    sub_title: "%200 BONUS BUY | %1000 SADAKAT BONUSU",
    logo: logoLigoBet,
    gradient: ["rgb(0,0,0)", "rgb(255,196,0)"],
    link: "https://cutt.ly/ligogir",
    silver: false
  }
];



const ButtonCard = (item) => {

  const [getHoverClass, setHoverClass] = useState(false);
  const [dotLottie, setDotLottie] = React.useState(null);
  const cardRef = useRef(null);

  const handleMouseEnter = () => {
    console.log("Mouse enter triggered!");
    cardRef.current.style.background = "lightgreen";
  };

  const triggerMouseEnter = () => {
    const mouseEnterEvent = new MouseEvent("mouseenter", {
      bubbles: true,
      cancelable: true,
    });
    cardRef.current.dispatchEvent(mouseEnterEvent);
  };


  useEffect(() => {
    if(item.index === 0){
      triggerMouseEnter();
    }
  },[cardRef])

  useEffect(() => {
    if(dotLottie !== null) {
      item.index === 0 && setTimeout(() => {
        dotLottie?.play()
      },100)
    }
  },[dotLottie])

  const dotLottieRefCallback = (dotLottie) => {
    setDotLottie(dotLottie);
  };

  return (
    <div
      ref={cardRef}
      className={`${getHoverClass && "rotating-border"} cardItem `}
      onMouseEnter={() => {
        setHoverClass(true);
        dotLottie?.play();
      }}
      onMouseLeave={() => {
        setHoverClass(false);
        dotLottie?.pause();
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        transition: "all 500ms ease-in-out",
        position: "relative",
        border: "0.2px solid rgb(255 255 255 / 52%)",
        marginBottom: 15,
        width: "100%",
        alignContent: "center",
        justifyContent: "space-evenly",
        height: getHoverClass ? 160 : 145,
        borderRadius: 30,
        backgroundImage: `linear-gradient(89deg, ${item.gradient[0]} 31%, ${item.gradient[1]} 122%)`
      }}>

      <div style={{flex:1}}>
        <img
          style={{marginTop: 20, marginLeft: 20, marginBottom: 0, maxWidth: 135}}
          src={item.logo}
          height={30} alt=""/>
        <p style={{
          fontFamily: 'Barlow',
          marginTop: 10,
          marginLeft: 20,
          fontWeight: 900,
          fontSize: 15,
          color: "white",
          marginBottom: 0
        }}>
          {item.title}
        </p>
        <p style={{
          fontFamily: 'Barlow',
          marginTop: 10,
          marginLeft: 20,
          fontWeight: 600,
          fontSize: 10,
          marginBottom: 9,
          color: "rgb(255 255 255 / 44%)"
        }}>
          {item.sub_title}
        </p>
      </div>

      <div
        style={{
          width:130,
          transition: "all 500ms ease-in-out",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...(getHoverClass ? {transform: "scale(1)",} : {transform: "scale(0.95)", height: "100%"}),
        }}
      >
        <a onClick={() => {
          window.open(item.link,'_blank');
        }} target={"_blank"} style={{textDecoration: "none"}}>
          <div
            style={{
              transition: "all 500ms ease-in-out",
              paddingLeft: "10px",
              paddingRight: "10px",
              background: "white",
              width: getHoverClass ? "100px" : "90px",
              height: "34px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              borderRadius: "16px",
              color: "black",
              fontSize: "16px",
              alignContent: "center",
              fontWeight: 900,
            }}
          >
            Siteye Git <ArrowRightOutlined/>
          </div>
        </a>
      </div>



    </div>
  )
}

function Mobile() {



  useEffect(() => {

    document.getElementsByName("cardItem")


  },[]);

  return (
    <div style={{
      overflow: "scroll",
      width: '100%',
      height: "100%",
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      position: "relative",
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>


      <div style={{
        opacity: 0.2,
        backgroundImage: `url(${require("./assets/bg.png")})`,
        position: "absolute",
        zIndex: 1,
        width: "100%",
        height: "100%",
        left: 0,
        top: 0
      }}/>


      <div style={{
        backgroundImage: `url(${require("./assets/gradient/day-4_2x.jpg")})`,
        backgroundSize: "100% 100%",
        position: "absolute",
        zIndex: 1,
        width: "100%",
        height: "100%",
        left: 0,
        top: 0
      }}/>



      <div style={{
        overflow: "hidden",
        position: "absolute",
        zIndex: 2,
        left: 0,
        top: 0,
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        width: "100%",
        height: 250
      }}>
        <DotLottieReact
          src={require('./assets/casino.lottie')}
          loop
          autoplay
          style={{height: 300, width: 300, position: "absolute", zIndex: 1, right: -100, top: -80}}
        />
      </div>


      <div style={{width: "90%", height: "100%", position: "absolute", zIndex: 2}}>
        <div style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          marginTop: 0,
          zIndex: 9
        }}>
          <p style={{
            textAlign: "left",
            fontFamily: "Barlow",
            fontSize: "35px",
            fontWeight: 900,
            marginBottom: 0,
            color: "white",
            WebkitTextFillColor: "transparent",
            background: "linear-gradient(90.11deg, rgb(236, 56, 77) 0.09%, rgb(238, 154, 27) 27.13%, rgb(146, 130, 239) 61.97%, rgb(255 52 0) 99.92%) text, text"
          }}> ŞİMDİ <br/> KAZANMA <br/> VAKTİ</p>
        </div>

        <div style={{display: "flex", gap: "1%", flexWrap: "wrap", marginTop:30}}>
          {

            data.map((item, index) => <ButtonCard {...item} index={index}/>)
          }
        </div>

      </div>
    </div>
  );
}

export default Mobile;
